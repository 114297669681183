/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */

/* Cursor Styles */
body {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z'%3E%3C/path%3E%3Ccircle cx='12' cy='13' r='4'%3E%3C/circle%3E%3Cline x1='8' y1='21' x2='16' y2='21'%3E%3C/line%3E%3Cline x1='12' y1='8' x2='12' y2='10'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='10' y2='12'%3E%3C/line%3E%3Cline x1='14' y1='12' x2='16' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='14' x2='12' y2='16'%3E%3C/line%3E%3C/svg%3E") 0 0, auto;
}

.dark body {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z'%3E%3C/path%3E%3Ccircle cx='12' cy='13' r='4'%3E%3C/circle%3E%3Cline x1='8' y1='21' x2='16' y2='21'%3E%3C/line%3E%3Cline x1='12' y1='8' x2='12' y2='10'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='10' y2='12'%3E%3C/line%3E%3Cline x1='14' y1='12' x2='16' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='14' x2='12' y2='16'%3E%3C/line%3E%3C/svg%3E") 0 0, auto;
}
